<template>
  <div v-if="loading">
    <loading></loading>
  </div>
  <div v-else id="inicio" class="index">

    <!-- Sección de título principal -->
    <div style="background-color: #0067a6; padding-top: 20px; padding-bottom: 20px;">
      <div class="col-md-12 animated fadeInUp">
        <div style="padding-top: 50px;">
          <h3 style="color: #ffffff !important" class="title">{{ data.inicio_title }}</h3>
        </div>
      </div>
    </div>

    <!-- Sección principal con Mapa y Carousel -->
    <div class="col-md-12">
      <div class="row">
        
        <!-- Columna con Mapa y subtítulo -->
        <div class="col-md-7 d-flex justify-content-center align-items-center">  
          
          <div class="row">
            

          <!-- 1426 - 1274 -->
            <div class="col-md-12 desktop-only" style="position: absolute; top: 6%; left: 0; width: 100%; display: flex; justify-content: center; align-items: flex-start;">
              <span class="desktop-only">
                <h4><strong>{{ data.servicios_tonelada }}</strong></h4>  
              </span>
            </div>

            <div class="col-md-12 bloqueSubtitulo">
              <span class="subtitulo">
                <strong>
                  {{ data.inicio_subtitle_1 }} <br> 
                  <span class="subtituloMarker"> {{ data.inicio_subtitle_2 }}</span>
                  <br> {{ data.inicio_subtitle_3 }}
                </strong>
              </span>
            </div>

            <div class="col-md-12" style="padding-top: 20px; padding-bottom: 30px;">
              <div>{{ data.inicio_ubicacion }}</div>
            </div>

            <!-- Imagen de Mapa -->
            <div class="col-md-11 bloqueMapa mobile-only">
              <img class="mapaCelaya" src="./../assets/img/punteroMapa.svg" alt="Mapa">
              <img class="punteroCelaya" src="./../assets/img/celayaMap.png" alt="Puntero">
            </div>
          </div>
        </div>

        <!-- Columna con Carousel -->
        <div class="col-md-5 d-flex justify-content-center align-items-center" style="padding: 0px; min-height: 10px !important;">
          <div>
            <div class="mobile-only" style="background-color: rgb(0, 103, 166); color: #ffffff; padding: 20px 0;">
              <h4><strong>{{ data.servicios_tonelada }}</strong></h4>
            </div>


            <!-- Carousel BootstrapVue desktop-->
            <b-carousel
              class="desktop-only"
              id="carousel-1"
              style="text-shadow: 0px 0px 2px #000"
              :interval="4000"
              controls
              indicators
              img-width="1024"
              img-height="800"
            >
              <b-carousel-slide class="crop" :img-src="carousel[0]"></b-carousel-slide>
              <b-carousel-slide class="crop" :img-src="carousel[1]"></b-carousel-slide>
              <b-carousel-slide class="crop" :img-src="carousel[2]"></b-carousel-slide>
              <b-carousel-slide class="crop" :img-src="carousel[3]"></b-carousel-slide>

            </b-carousel>
           

             <!-- Carousel BootstrapVue mobile-->
             <b-carousel
              class="mobile-only"
              id="carousel-2"
              v-model="slide"
              :interval="6000"
              controls
              indicators
              background="#ababab"
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333; padding: 0px;"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <!-- Otros Slides con imágenes desde Firebase -->
              <b-carousel-slide :img-src="carousel[1]"></b-carousel-slide>
              <b-carousel-slide :img-src="carousel[2]"></b-carousel-slide>
              <b-carousel-slide :img-src="carousel[3]"></b-carousel-slide>

            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from './../components/loading.vue'

export default {
  name: 'index',
  components: {
    loading
  },
  mounted() {
    this.init()
  },
  methods: {
    async onSlideStart() {
      this.sliding = true
    },
    async onSlideEnd() {
      this.sliding = false
    },
    async init() {
      this.loading = true
      this.data = await null
      this.data = await JSON.parse(window.localStorage.getItem('lang'))
      this.loading = false
    }
  },
  data() {
    return {
      carousel: [
        'https://firebasestorage.googleapis.com/v0/b/fletram-41247.appspot.com/o/Imagenes%2Ff0.png?alt=media&token=e9a404d5-c3e1-4ad0-b93e-c07912a17682',
        'https://firebasestorage.googleapis.com/v0/b/fletram-41247.appspot.com/o/Imagenes%2Ff1.png?alt=media&token=44665714-f448-48d1-ab08-fbc188d8e1c8',
        'https://firebasestorage.googleapis.com/v0/b/fletram-41247.appspot.com/o/Imagenes%2Ff2.png?alt=media&token=7e15de16-0b04-4a06-912e-6bc1b791a97e',
        'https://firebasestorage.googleapis.com/v0/b/fletram-41247.appspot.com/o/Imagenes%2Ff3.png?alt=media&token=da37b53e-07e6-4f5f-a8a3-76ecafe23fc9'
      ],
      slide: 0,
      sliding: null,
      loading: true,
      data: JSON.parse(window.localStorage.getItem('lang'))
    }
  }
}
</script>

<style scoped>


#carousel-1 {
  height: 100%; /* Fija la altura del carrusel */
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden !important; /* Oculta cualquier desbordamiento */
}

.crop {
  max-width: 100%;
  max-height: 800px; /* Fija la altura de las imágenes */
  overflow: hidden !important;
}

.crop img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta las imágenes para que llenen el contenedor */
  margin: 0 !important;
}



.index {
  padding-top: 111px;
}

.desktop-only {
  display: none;
}

.mobile-only {
  display: none;
}

.index #idimagen {
  text-align: center;
  margin: 50px auto;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.index #idimagen:hover {
  transform: scale(1.05);
}

.index #idimagen img {
  width: 100%;
  border-radius: 10px;
}

.index .title {
  color: rgb(44 62 80);
  font-size: 60px;
  font-weight: bolder;
  text-align: center;
  padding-bottom: 10px;
}

.index .bloqueGeneral {
  padding-top: 100px;
  padding-bottom: 200px;
}

.index .ubicacionTexto {
  text-align: center;
  top: 30px;
  left: 30px;
}

.index .bloqueSubtitulo {
  padding-top: 30px;
}

.index .subtitulo {
  text-align: center;
  font-size: 50px;
}

.index .subtituloMarker {
  color: #dd132a;
}

.index .bloqueMapa {
  padding-top: 50px;
  padding-right: 100px;
  padding-bottom: 50px;
}

.index .mapaCelaya {
  width: 100px;
  position: relative;
  left: 137px;
}

.index .punteroCelaya {
  width: 233px;
}


@media (max-width: 1280px) {
  #carousel-1 {
    height: 410px; /* Fija la altura del carrusel */
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden; /* Oculta cualquier desbordamiento */
  }

  .crop {
    max-width: 100%;
    max-height: 410px; /* Fija la altura de las imágenes */
    overflow: hidden;
  }

  .crop img {
    width: 100%;
    height: 410px;
    object-fit: fill; /* Ajusta las imágenes para que llenen el contenedor */
    margin: 0;
  }
  
  .index .bloqueSubtitulo {
    padding-top: 100px;
  }

}
@media (max-width: 991px) {
  .index .title {
    font-size: 40px;
  }

  .index .bloqueGeneral {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .index .subtitulo {
    font-size: 30px;
  }

  .index .mapaCelaya {
    left: 140px;
  }

  .index .punteroCelaya {
    width: 250px;
  }
}

@media (min-width: 768px) {
  .desktop-only {
    display: block;
  }
}

@media (max-width: 767px) {

  .mobile-only {
    display: block;
  }
  .index .title {
    font-size: 40px;
  }
  .index .bloqueGeneral {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .index .subtitulo {
    font-size: 30px;
  }
  .index .mapaCelaya {
    left: 140px;
    top: -20px;
  }
  .index .punteroCelaya {
    width: 250px;
  }
}
</style>
