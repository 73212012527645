<template>
  <div v-if="loading">
    <loading></loading>
  </div>
  <div v-else>
    <b-navbar fixed="top" toggleable="lg" type="info" variant="info" class="border-bottom">
      <div class="container">
        <b-navbar-brand href="/">
          <img src="./../assets/logos/LogoFletram.jpg" class="logo" alt="">
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
          <b-icon icon="justify"></b-icon>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-nav-item>
                <router-link to="/"><strong>{{ data.menu_inicio }}</strong></router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/conocenos"><strong>{{ data.menu_conocenos }}</strong></router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/servicios"><strong>{{ data.menu_servicios }}</strong></router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/contacto"><strong>{{ data.menu_contacto }}</strong></router-link>
              </b-nav-item>
              <b-nav-item v-on:click="español(true)">
                <img src="./../assets/img/mex.svg" class="flag" alt="">
              </b-nav-item>
              <b-nav-item v-on:click="ingles(true)">
                <img src="./../assets/img/usa.svg" class="flag" alt="">
              </b-nav-item>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import lang from './../assets/langs.json'
import loading from './loading.vue'

export default {
  name: 'headn',
  data() {
    return {
      loading: false,
      data: JSON.parse(window.localStorage.getItem('lang')),
      langSelected: { ...lang[0] }
    }
  },
  components: {
    loading
  },
  mounted() {
    this.español(false)
  },
  methods: {
    async español(type) {
      if (this._.isEmpty(JSON.parse(window.localStorage.getItem('lang'))) || type === true) {
        this.loading = true
        this.langSelected = { ...lang[0] }
        window.localStorage.removeItem('lang')
        window.localStorage.setItem('lang', JSON.stringify(this.langSelected))
        this.data = JSON.parse(window.localStorage.getItem('lang'))
        location.reload()
        this.loading = false
      }
    },
    async ingles(type) {
      if (this._.isEmpty(JSON.parse(window.localStorage.getItem('lang'))) || type === true) {
        this.loading = true
        this.langSelected = { ...lang[1] }
        window.localStorage.removeItem('lang')
        window.localStorage.setItem('lang', JSON.stringify(this.langSelected))
        this.data = JSON.parse(window.localStorage.getItem('lang'))
        location.reload()
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.logo {
  width: 120px;
}

.flag {
  width: 25px;
  cursor: pointer;
}

@media (max-width: 850px) and (orientation: landscape) {
  .logo {
    width: 170px !important;
  }

  .form-inline {
    display: block !important;
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .logo {
    width: 140px !important;
  }

  .form-inline {
    display: block !important;
  }
}

.navbar-toggler {
  border: 1px solid #1d1d1d45;
  color: #000000 !important;
}

.bg-info {
  background-color: #ffffff !important;
  color: #012d33 !important;
}

a {
  color: #0267a6;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0267a6;
  text-decoration: none;
  background-color: transparent;
}
</style>
